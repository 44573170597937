import React from 'react';

import { CustomTablePagination } from '../../components/Table';

const ListProduct = ({ products, responseDeleteProduct, loading }) => {
  // evento para borrar un producto
  const handleDeleteProduct = async (idProduct) => {
    responseDeleteProduct(idProduct);
  };

  // definimos el header de la tabla
  const columns = [
    { id: 'name', label: 'Producto', minWidth: 170 },
    { id: 'price', label: 'Precio', minWidth: 90, align: 'center' },
    {
      id: 'category',
      label: 'Categoria',
      minWidth: 60,
      align: 'center',
    },
  ];

  // definimos el cuerpo de la tabla
  const rows = products.map((product) => {
    return {
      name: product.name,
      price: product.price,
      category: product.category,
      id: product.id,
    };
  });

  return (
    <CustomTablePagination
      header={columns}
      body={rows}
      handleDelete={handleDeleteProduct}
      loading={loading}
    />
  );
};

export default ListProduct;
