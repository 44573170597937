import React from 'react';
import ReactDOM from 'react-dom';
import clsx from 'clsx';

//css
import './modal.scss';

function Modal({ children, onClose, size, customClass = '' }) {
  const classes = clsx('modal_content', size && `modal-${size}`, customClass);

  return (
    <div className='modal'>
      <div className={classes}>
        {onClose && (
          <button className='modal_close' onClick={onClose}>
            𝗫
          </button>
        )}
        {children}
      </div>
    </div>
  );
}

export default function ModalMirror({ children, onClose, size, customClass }) {
  return ReactDOM.createPortal(
    <Modal onClose={onClose} size={size} customClass={customClass}>
      {children}
    </Modal>,
    document.getElementById('modal-root'),
  );
}
