import React from 'react';
import { ModalMirror } from './';

import { CustomInput } from '../Input';
import { CustomButton } from '../Button';

import './modalChangeQuantity.scss';

const ModalChangeQuantity = ({
  onClose,
  onChange,
  disabled,
  onClickCheck,
  quantity,
}) => {
  return (
    <ModalMirror onClose={onClose}>
      <div className='modalChangeQuantity'>
        <CustomInput
          label=''
          id='quantity'
          type='number'
          value={quantity}
          handleChange={onChange}
          width={0}
          size='sm'
        />
        <div className='modal_change_quantity_btns'>
          <CustomButton
            color='secondary'
            handleClick={onClose}
            disabled={disabled}
            text='Cancelar'
          />
          <CustomButton
            color='primary'
            handleClick={onClickCheck}
            disabled={disabled}
            text='Cambiar'
          />
        </div>
      </div>
    </ModalMirror>
  );
};

export default ModalChangeQuantity;
