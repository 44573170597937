import React, { useEffect, useState, useContext } from 'react';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { Alert } from '@material-ui/lab';

// Context
import Context from '../../context/UserContext';

// Hooks
import useProduct from '../../hooks/useProduct';
import useShopping from '../../hooks/useShopping';

// Components
import { CustomTablePagination } from '../../components/Table';
import { CustomInput } from '../../components/Input';
import { CustomButton } from '../../components/Button';
import { ModalShopping, ModalMirror } from '../../components/Modal';
import DetailsAddCart from './DetailsAddCart';
import { Loader } from '../../components/Loader';

// Image
import CartImage from '../../assets/image/cart.svg';

import './listProductPage.scss';

const ListProductPage = () => {
  const { listProduct, loadingProduct, getProduct, setListProduct } =
    useProduct();
  const {
    loadingShopping,
    successShopping,
    errorShopping,
    count,
    addShopping,
    countShopping,
    setSuccessShopping,
    setErrorShopping,
  } = useShopping();
  const [productAdd, setProductAdd] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [errorPage, setErrorPage] = useState(false);
  const [filter, setFilter] = useState({
    product: '',
    category: '',
  });
  const { product } = useContext(Context);
  const [modalShopping, setModalShopping] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  useEffect(async () => {
    await getProduct();
    await countShopping();
  }, []);

  // function para limpiar los mensajes
  const handleClearMessage = () => {
    if (errorPage) {
      setErrorPage(false);
    }

    if (successShopping) {
      setSuccessShopping(false);
    }

    if (errorShopping) {
      setErrorShopping(false);
    }
  };

  // evento para guardar el producto que sera enviado al carrito
  const handlePreAddShopping = (product) => {
    // limpiamos en caso de que hayan mensajes
    handleClearMessage();

    // limpiamos la cantidad en caso de que haya
    if (quantity !== 0) {
      setQuantity(0);
    }

    setProductAdd(product);
  };

  // evento para guardar la cantidad del producto
  const handleAddQuantity = (e) => {
    setQuantity(e.target.value);
  };

  // evento para agregar el producto al carrito
  const handleClickAddShopping = async () => {
    // limpiamos en caso de que hayan mensajes
    handleClearMessage();

    // si el producto no tiene cantidad damos error
    if (parseInt(quantity, 10) <= 0) {
      setErrorPage('Ingrese una cantidad mayor a 0');
      return;
    }

    const data = {
      id_product: productAdd.id,
      quantity,
      price: productAdd.price,
    };

    await addShopping(data);
    await countShopping();
  };

  // evento para limpiar los datos almacenados
  const handleClear = () => {
    setProductAdd([]);
    setQuantity(0);
  };

  // evento para buscar el producto por su nombre
  const handleFilter = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });

    // buscamos por nombre de producto
    if (e.target.name === 'product') {
      setListProduct(
        product.filter((item) =>
          item.name.toLowerCase().includes(e.target.value.toLowerCase()),
        ),
      );
    }

    // buscamos por categoria
    if (e.target.name === 'category') {
      setListProduct(
        product.filter((item) => item.category === e.target.value),
      );
    }
  };

  // evento para abrir el modal del carrito
  // clear nos sirve para saber si limpiar el carrito o no
  const handleModalShopping = async (clear = false) => {
    // si se hace un pedido tenemos que limpiar el carrito y por eso nos viene true
    if (clear === true) {
      await countShopping();
      // mostramos modal de que el pedido fue exitoso
      setModalMessage('Su pedido fue enviado con exito');
      setProductAdd([]);
      setTimeout(() => {
        setModalMessage('');
      }, 2000);
    }
    setModalShopping(!modalShopping);
  };

  if (loadingProduct) return <Loader />;

  // definimos el header de la tabla
  const columns = [
    { id: 'name', label: 'Producto', minWidth: 170 },
    { id: 'price', label: 'Precio', minWidth: 90, align: 'center' },
    {
      id: 'category',
      label: 'Categoria',
      minWidth: 60,
      align: 'center',
    },
  ];

  // definimos el cuerpo de la tabla
  const rows = listProduct.map((product) => {
    return {
      name: product.name,
      price: product.price,
      category: product.category,
      id: product.id,
    };
  });

  // ! COLOCAR LA LISTA DE CATEGORIA
  const category = [
    {
      category: 'DI',
    },
    {
      category: 'LP',
    },
    {
      category: 'LS',
    },
    {
      category: 'P',
    },
    {
      category: 'R',
    },
    {
      category: 'V',
    },
    {
      category: 'VM',
    },
  ];

  return (
    <div className='listProductPage'>
      <div className='listProductPage_table'>
        <div className='listProductPage_filter'>
          <CustomInput
            label='Producto'
            id='product'
            type='text'
            value={filter.product}
            handleChange={handleFilter}
            width={65}
          />
          <CustomInput
            label='Categoria'
            id='category'
            type='select'
            value={filter.category}
            handleChange={handleFilter}
            width={70}
            options={category || []}
            title='category'
          />
        </div>
        <CustomTablePagination
          header={columns}
          body={rows}
          handleClick={handlePreAddShopping}
        />
      </div>
      <div className='listProductPage_shopping'>
        {productAdd.length !== 0 ? (
          <>
            {successShopping && (
              <Alert severity='success'>El producto se agrego al carrito</Alert>
            )}
            {(errorShopping || errorPage) && (
              <Alert severity='error'>{errorShopping || errorPage}</Alert>
            )}

            <CustomInput
              label='Cantidad'
              id='quantity'
              type='number'
              value={quantity}
              handleChange={handleAddQuantity}
              width={65}
              size='sm'
            />

            <DetailsAddCart productAdd={productAdd} quantity={quantity} />

            <div className='listProductPage_shopping_btns'>
              <CustomButton
                color='secondary'
                handleClick={handleClear}
                disabled={loadingShopping}
                text='Cancelar'
              />
              <CustomButton
                color='primary'
                handleClick={handleClickAddShopping}
                disabled={loadingShopping}
                text='Agregar'
              />
            </div>
          </>
        ) : (
          <div className='listProductPage_img'>
            <img src={CartImage} alt='cart' />
          </div>
        )}
      </div>
      <div
        className='listProductPage_shopping_car'
        onClick={handleModalShopping}
      >
        <ShoppingCartIcon />
        <span>{count}</span>
      </div>

      {modalShopping && (
        <ModalShopping
          onClose={handleModalShopping}
          countShopping={countShopping}
        />
      )}

      {modalMessage && (
        <ModalMirror onClose={() => setModalMessage('')}>
          <Alert severity='success'>{modalMessage}</Alert>
        </ModalMirror>
      )}
    </div>
  );
};

export default ListProductPage;
