import React, { useState, useEffect } from 'react';
import { Alert } from '@material-ui/lab';

// Hooks
import useOrder from '../../hooks/useOrder';
import useOrderProduct from '../../hooks/useOrderProduct';

// Components
import { CustomTablePagination } from '../../components/Table';
import ExcelSvg from '../../components/Svg/ExcelSvg';
import { ModalConfirm, ModalMirror } from '../../components/Modal';
import { CustomButton } from '../../components/Button';
import { Loader } from '../../components/Loader';

// image
import OrderProductImage from '../../assets/image/orderproduct.svg';

// style
import './listOrderPage.scss';

const ListOrderPage = () => {
  const {
    listOrder,
    loadingOrder,
    loadingReport,
    getOrder,
    reportOrder,
    deleteOrder,
    setListOrder,
  } = useOrder();
  const { listOrderProduct, loadingOrderProduct, getOrderProduct } =
    useOrderProduct();
  const [selectOrder, setSelectOrder] = useState(0);
  const [fileExport, setFileExport] = useState('');
  const [modalConfirm, setModalConfirm] = useState({
    isOpen: false,
    order: 0,
  });

  useEffect(async () => {
    await getOrder();
  }, []);

  // evento para buscar el detalle del pedido
  const handleSerachDetailsOrder = async (order) => {
    // si tenemos un archivo de descarga lo limpiamos
    if (fileExport !== '') {
      setFileExport('');
    }
    // buscamos el detalle del pedido
    await getOrderProduct(`?id_order=${order.id}`);
    setSelectOrder(order.id);
  };

  // evento para crear el reporte en excel
  const handleExportExcel = async (idOrder = false) => {
    // si recivimos idOrder es porque queremos un reporte detallado
    if (idOrder) {
      const file = await reportOrder(`?id_order=${idOrder}`);
      setFileExport(file);
    } else {
      const file = await reportOrder('');
      setFileExport(file);
    }
  };

  // evento para abrir el modal para confirmar la eliminacion del pedido
  const handleOpenModalConfirm = (idOrder) => {
    setModalConfirm({
      isOpen: true,
      order: idOrder,
    });
  };

  // evento para eliminar el pedido
  const handleDeleteOrder = async () => {
    // validamos que haya un id de pedido
    if (modalConfirm.order === 0) return;

    const validate = await deleteOrder({ id: modalConfirm.order });

    if (validate === true) {
      setListOrder(listOrder.filter((item) => item.id !== modalConfirm.order));
    }

    setModalConfirm({
      isOpen: false,
      order: 0,
    });
  };

  if (loadingOrder)
    return (
      <ModalMirror customClass='modal_transparent'>
        <Loader />
      </ModalMirror>
    );

  // definimos el header de la tabla
  const columns = [
    { id: 'number', label: 'Pedido', minWidth: 60 },
    { id: 'date_order', label: 'Fecha', minWidth: 90, align: 'center' },
    { id: 'name', label: 'Cliente', minWidth: 170, align: 'center' },
    {
      id: 'total',
      label: 'Total',
      minWidth: 60,
      align: 'center',
    },
  ];

  // definimos el cuerpo de la tabla
  const rows = listOrder.map((product) => {
    return {
      number: `${product.num_order}`,
      date_order: product.date_order,
      name: product.name,
      total: product.total,
      id: product.id,
    };
  });

  let columns2;
  let rows2;

  // validamos que existan datos en el detalle del producto
  if (listOrderProduct.length !== 0) {
    // definimos el header de la tabla
    columns2 = [
      { id: 'name', label: 'Producto', minWidth: 100 },
      { id: 'quantity', label: 'Cantidad', minWidth: 50, align: 'center' },
      { id: 'subTotal', label: 'subTotal', minWidth: 50, align: 'right' },
    ];

    // definimos el cuerpo de la tabla
    rows2 = listOrderProduct.map((product) => {
      return {
        name: product.name,
        quantity: `${product.quantity}`,
        subTotal: product.subTotal,
        id: product.id,
      };
    });
  }

  return (
    <div className='listOrderPage'>
      <div className='listOrderPage_item'>
        <div className='listOrderPage_top'>
          <h2>Lista de Pedidos</h2>
          <CustomButton
            color='secondary'
            handleClick={() => handleExportExcel('')}
            disabled={loadingReport}
            size='sm'
            transparent
          >
            <ExcelSvg />
          </CustomButton>
        </div>
        <CustomTablePagination
          header={columns}
          body={rows}
          handleClick={handleSerachDetailsOrder}
          handleDelete={handleOpenModalConfirm}
        />
      </div>

      {listOrderProduct.length !== 0 ? (
        <div className='listOrderPage_item'>
          <div className='listOrderPage_top'>
            <h2>Detalles del Pedido</h2>
            <CustomButton
              color='secondary'
              handleClick={() => handleExportExcel(selectOrder)}
              disabled={loadingReport}
              size='sm'
              transparent
            >
              <ExcelSvg />
            </CustomButton>
          </div>
          <CustomTablePagination
            header={columns2}
            body={rows2}
            handleClick={handleSerachDetailsOrder}
          />
        </div>
      ) : (
        <div className='listOrderProduct_img'>
          <img src={OrderProductImage} alt='Pedidos' />
        </div>
      )}

      {fileExport !== '' && (
        <ModalMirror onClose={() => setFileExport('')}>
          <Alert severity='success'>
            <a
              href={fileExport}
              download='nombre'
              className='listOrderProduct_download'
            >
              Descargar
            </a>
          </Alert>
        </ModalMirror>
      )}

      {modalConfirm.isOpen && (
        <ModalConfirm
          message='¿ Desea eliminar el pedido ?'
          onCofirm={handleDeleteOrder}
          onClose={() => setModalConfirm({ isOpen: false, order: 0 })}
        />
      )}

      {(loadingOrderProduct || loadingReport) && (
        <ModalMirror customClass='modal_transparent'>
          <Loader />
        </ModalMirror>
      )}
    </div>
  );
};

export default ListOrderPage;
