import { useState, useContext } from 'react';

// Context
import Context from '../context/UserContext';

// Services
import fetchService from '../services/fetch';

const endpoint = 'product';

const useProduct = () => {
  const { jwt } = useContext(Context);
  const [listProductAdmin, setListProductAdmin] = useState([]);
  const [loadingProductAdmin, setLoadingProductAdmin] = useState(false);
  const [errorProductAdmin, setErrorProductAdmin] = useState(false);

  const getProductAdmin = async (filter = '') => {
    try {
      setLoadingProductAdmin(true);
      const res = await fetchService({
        method: 'GET',
        jwt,
        endpoint,
        filter,
      });

      if (res.error) {
        throw new Error(res.body);
      }

      setListProductAdmin(res.body);
    } catch (err) {
      setErrorProductAdmin(err.message);
    } finally {
      setLoadingProductAdmin(false);
    }
  };

  return {
    listProductAdmin,
    loadingProductAdmin,
    errorProductAdmin,
    getProductAdmin,
    setListProductAdmin,
  };
};

export default useProduct;
