import React from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';

// Components
import DashboardAdmin from './DashboardAdmin';

// Router
import OrderRouter from '../Orders/OrderRouter';
import ClientRouter from '../Client/ClientRouter';

const DashboardRouter = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}/`} component={DashboardAdmin} />
      <Route path={`${match.url}/order`} component={OrderRouter} />
      <Route path={`${match.url}/client`} component={ClientRouter} />
      <Redirect to={`${match.url}/`} />
    </Switch>
  );
};

export default DashboardRouter;
