import React, { useState } from 'react';
import clsx from 'clsx';
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';

import { CustomButton } from '../Button';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  tableHeader: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontWeight: 'bold',
  },
  click: {
    cursor: 'pointer',
  },
}));

const CustomTablePagination = ({
  header,
  body,
  handleClick,
  handleDelete,
  loading,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // evento para cambiar el numero de la pagina de la tabla
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // funcion para cambiar la cantidad de items por pagina a mostrar
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {header.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className={classes.tableHeader}
                >
                  {column.label}
                </TableCell>
              ))}

              {handleDelete && (
                <TableCell className={classes.tableHeader}></TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {body
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow
                    hover
                    role='checkbox'
                    tabIndex={-1}
                    key={row.id}
                    onClick={handleClick ? () => handleClick(row) : () => false}
                    className={clsx(handleClick && classes.click)}
                  >
                    {header.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id + row.id}
                          align={column.align}
                        >
                          {typeof value === 'number' ? `$ ${value}` : value}
                        </TableCell>
                      );
                    })}

                    {handleDelete && (
                      <TableCell>
                        <CustomButton
                          color='secondary'
                          handleClick={() => handleDelete(row.id)}
                          disabled={loading}
                          size='sm'
                          text='X'
                        />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component='div'
        count={body.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default CustomTablePagination;
