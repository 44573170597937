import { useState, useContext } from 'react';

// Context
import Context from '../context/UserContext';

// Services
import fetchService from '../services/fetch';

const endpoint = 'shopping';

const useProduct = () => {
  const { jwt } = useContext(Context);
  const [listShopping, setListShopping] = useState([]);
  const [loadingShopping, setLoadingShopping] = useState(false);
  const [errorShopping, setErrorShopping] = useState(false);
  const [successShopping, setSuccessShopping] = useState(false);
  const [count, setCount] = useState(0);

  const getShopping = async () => {
    try {
      setLoadingShopping(true);
      const res = await fetchService({
        method: 'GET',
        jwt,
        endpoint,
      });

      if (res.error) {
        throw new Error(res.body);
      }

      setListShopping(res.body);
    } catch (err) {
      setErrorShopping(err.message);
    } finally {
      setLoadingShopping(false);
    }
  };

  const addShopping = async (data) => {
    try {
      setLoadingShopping(true);
      const res = await fetchService({
        method: 'POST',
        jwt,
        endpoint,
        data,
      });

      if (res.error) {
        throw new Error(res.vody);
      }

      setSuccessShopping(true);
    } catch (err) {
      setErrorShopping(err.message);
    } finally {
      setLoadingShopping(false);
    }
  };

  // obtenemos la cantidad de productos que tiene en el carrito
  const countShopping = async () => {
    try {
      const res = await fetchService({
        method: 'GET',
        jwt,
        endpoint: endpoint + '/count',
      });

      if (res.error) {
        throw new Error(res.body);
      }

      setCount(res.body);
    } catch (err) {
      setErrorShopping(err.message);
    }
  };

  // eliminamos el producto del carrito
  const removeShopping = async (data) => {
    try {
      setLoadingShopping(true);
      const res = await fetchService({
        method: 'DELETE',
        jwt,
        endpoint,
        data,
      });

      if (res.error) {
        throw new Error(res.body);
      }

      return true;
    } catch (err) {
      setErrorShopping(err.message);
    } finally {
      setLoadingShopping(false);
    }
  };

  return {
    listShopping,
    loadingShopping,
    errorShopping,
    successShopping,
    count,
    getShopping,
    addShopping,
    countShopping,
    removeShopping,
    setListShopping,
    setSuccessShopping,
    setErrorShopping,
  };
};

export default useProduct;
