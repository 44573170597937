import React from 'react';
import clsx from 'clsx';
import {
  makeStyles,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  MenuItem,
} from '@material-ui/core';

import './input.scss';

const useStyles = makeStyles((theme) => ({
  formControlSelect: {
    minWidth: 120,
  },
}));

const CustomInput = ({
  label,
  id,
  type,
  value,
  width,
  handleChange,
  options,
  title,
  size = 'lg',
}) => {
  const classes = useStyles();
  const classInput = clsx(`input-${size}`);

  if (type === 'select')
    return (
      <FormControl variant='outlined' className={classes.formControlSelect}>
        <InputLabel id={id}>{label}</InputLabel>
        <Select
          defaultValue={value}
          onChange={handleChange}
          labelWidth={width}
          value={value}
          inputProps={{
            name: id,
            id,
          }}
        >
          {options.map((item) => (
            <MenuItem key={item[title]} value={item[title]}>
              {item[title]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );

  return (
    <FormControl className={classInput} variant='outlined'>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <OutlinedInput
        id={id}
        type={type}
        value={value}
        name={id}
        onChange={handleChange}
        labelWidth={width}
      />
    </FormControl>
  );
};

export default CustomInput;
