import React from 'react';
import clsx from 'clsx';
import { Button } from '@material-ui/core';

// style
import './button.scss';

const CustomButton = ({
  handleClick,
  disabled,
  color,
  text,
  size,
  children,
  transparent,
  type = 'button',
}) => {
  const classes = clsx(
    size && `button-${size}`,
    transparent && 'button-tanspatent',
  );

  return (
    <Button
      className={classes}
      variant='contained'
      color={color}
      onClick={handleClick}
      disabled={disabled}
      type={type}
    >
      {text || children}
    </Button>
  );
};

export default CustomButton;
