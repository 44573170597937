import React from 'react';

import { CustomTableList } from '../../components/Table';

const DetailsUser = ({ user }) => {
  // definimos el header de la tabla
  const data = [
    { id: 'name', label: 'Nombre', align: 'right' },
    { id: 'last_name', label: 'Apellido', align: 'right' },
    {
      id: 'email',
      label: 'Email',
      align: 'right',
    },
    {
      id: 'freight',
      label: 'Flete',
      align: 'right',
    },
  ];

  return <CustomTableList body={user} titles={data} />;
};

export default DetailsUser;
