import React from 'react';

import { CustomTableList } from '../../components/Table';

const DetailsAddCart = ({ productAdd, quantity }) => {
  const data = [
    { id: 'name', label: 'Producto', align: 'right' },
    { id: 'price', label: 'Precio', align: 'right' },
    {
      id: 'quantity',
      label: 'Cantidad',
      align: 'right',
    },
    {
      id: 'total',
      label: 'Total',
      align: 'right',
    },
  ];

  const body = {
    name: productAdd.name,
    price: `$ ${productAdd.price}`,
    quantity,
    total: `$ ${parseFloat(quantity * productAdd.price).toFixed(2)}`,
  };

  return <CustomTableList body={body} titles={data} />;
};

export default DetailsAddCart;
