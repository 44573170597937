import { useContext, useCallback, useState } from 'react';
import Context from '../context/UserContext';
import fetchService from '../services/fetch';

const endpoint = 'auth';

export default function useLogin() {
  const { jwt, setJWT, setUser, setProduct } = useContext(Context);
  const [errorLogin, setErrorLogin] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);

  const login = useCallback(
    async (data) => {
      try {
        setLoadingLogin(true);
        const res = await fetchService({
          endpoint,
          data,
          method: 'POST',
        });

        if (res.error) {
          throw new Error(res.error);
        }

        window.localStorage.setItem('jwt', res.body.token);
        window.localStorage.setItem('user', JSON.stringify(res.body.user));
        setUser(res.body.user);
        setJWT(res.body.token);
      } catch (err) {
        setErrorLogin(err.message);
      } finally {
        setLoadingLogin(false);
      }
    },
    [setJWT],
  );

  const logout = useCallback(async () => {
    try {
      setLoadingLogin(true);
      const res = await fetchService({
        endpoint,
        method: 'DELETE',
        jwt,
        data: { token: jwt },
      });

      if (res.error) {
        throw new Error(res.error);
      }

      window.localStorage.clear();
      setJWT('');
      setUser(null);
      setProduct([]);
    } catch (err) {
      setErrorLogin(err.message);
    }
  }, []);

  return {
    isLogged: Boolean(jwt),
    errorLogin,
    loadingLogin,
    login,
    logout,
  };
}
