import React from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';

// Components
import ListProductPage from './ListProductPage';

const DashboardRouter = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.url}/`} component={ListProductPage} />
      <Redirect to={`${match.url}/`} />
    </Switch>
  );
};

export default DashboardRouter;
