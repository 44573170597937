import React from 'react';

import './footer.scss';

const Footer = () => (
  <div className='footer'>
    <p>
      &copy; Copyright 2021. Derechos reservados. Desarrollado por{' '}
      <a
        href='https://virtualcodecam.com/?utm_source=fioretto&utm_medium=system-fioretto&utm_campaign=system-fioretto'
        title='Agencia de Desarrollo de Páginas Web'
      >
        Agencia VirtualCodeCAM
      </a>
    </p>
  </div>
);

export default Footer;
