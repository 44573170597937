import React from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';

// components
import ListOrderPage from './ListOrderPage';

const OrderRouter = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}`} component={ListOrderPage} />
      <Redirect to={`${match.url}/`} />
    </Switch>
  );
};

export default OrderRouter;
