import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

// Hooks
import useUser from '../../hooks/useUser';

// Components
import { Title } from '../../components/Title';
import { Form } from '../../components/Form';
import { Footer } from '../../components/Footer';

// Img
import StoreImage from '../../assets/image/icono.png';
import AvatarMan from '../../assets/image/avatar-man.svg';
import BgLogin from '../../assets/image/bg-login.svg';

// Css
import './login.scss';
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: '25ch',
  },
}));

const RegisterPasswordPage = () => {
  const { registerPassword, loadingUser } = useUser();
  const [showPassword, setShowPassword] = useState(false);
  const [dataForm, setDataForm] = useState({
    email: '',
    password: '',
    repit_password: '',
  });
  const [errorForm, setErrorForm] = useState('');
  const history = useHistory();

  const classForm = useStyles();

  const handleChange = (e) => {
    setDataForm({
      ...dataForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (value) => {
    // validamos que las contraseñas coincidan
    if (value.password === value.repit_password) {
      const validate = await registerPassword(value);

      if (validate === true) {
        history.push('/session/signin');
      }
    } else {
      setErrorForm('Las contraseñas no coinciden');
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className='login'>
      <div className='login_svg'>
        <img className='login_svg_bg' src={BgLogin} alt='Fondo Login' />
        <img className='login_svg_truck' src={StoreImage} alt='Tienda' />
      </div>
      <div className='login_content'>
        <img className='login_icon' src={AvatarMan} alt='Perfil' />
        <div className='login_title'>
          <Title size='xl'>REGISTRAR</Title>
        </div>

        <Form onSubmit={handleSubmit}>
          {errorForm && <Alert severity='error'>{errorForm}</Alert>}

          <div className='login_form'>
            <FormControl
              className={clsx(classForm.margin, classForm.textField)}
              variant='outlined'
            >
              <InputLabel htmlFor='email'>Correo</InputLabel>
              <OutlinedInput
                id='email'
                type='email'
                value={dataForm.email}
                name='email'
                required
                onChange={handleChange}
                labelWidth={50}
              />
            </FormControl>

            <FormControl
              className={clsx(classForm.margin, classForm.textField)}
              variant='outlined'
            >
              <InputLabel htmlFor='password'>Contraseña</InputLabel>
              <OutlinedInput
                color='primary'
                id='password'
                type={showPassword ? 'text' : 'password'}
                value={dataForm.password}
                name='password'
                required
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={90}
              />
            </FormControl>

            <FormControl
              className={clsx(classForm.margin, classForm.textField)}
              variant='outlined'
            >
              <InputLabel htmlFor='repit_password'>
                Repetir contraseña
              </InputLabel>
              <OutlinedInput
                color='primary'
                id='repit_password'
                type={showPassword ? 'text' : 'password'}
                value={dataForm.repit_password}
                name='repit_password'
                required
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={140}
              />
            </FormControl>

            <Button
              type='submit'
              variant='contained'
              color='primary'
              disabled={loadingUser}
            >
              REGISTRAR
            </Button>
          </div>
        </Form>
      </div>

      <Footer />
    </div>
  );
};

export default RegisterPasswordPage;
