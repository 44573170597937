import React from 'react';

const Form = ({ children, onSubmit }) => {
  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const formDataObj = {};

    formData.forEach((value, key) => {
      formDataObj[key] = value;
    });

    onSubmit && onSubmit(formDataObj);
  };

  return <form onSubmit={handleSubmit}>{children}</form>;
};

export default Form;
