import { useState, useContext } from 'react';

// Context
import Context from '../context/UserContext';

// Services
import fetchService from '../services/fetch';

const endpoint = 'order';

const useOrder = () => {
  const { jwt } = useContext(Context);
  const [listOrder, setListOrder] = useState([]);
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [errorOrder, setErrorOrder] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);

  const getOrder = async () => {
    try {
      setLoadingOrder(true);
      const res = await fetchService({
        method: 'GET',
        jwt,
        endpoint,
      });

      if (res.error) {
        throw new Error(res.body);
      }

      setListOrder(res.body);
    } catch (err) {
      setErrorOrder(err.message);
    } finally {
      setLoadingOrder(false);
    }
  };

  const addOrder = async (data) => {
    try {
      setLoadingOrder(true);

      const res = await fetchService({
        method: 'POST',
        jwt,
        endpoint,
        data,
      });

      if (res.error) {
        throw new Error(res.body);
      }

      return res.body;
    } catch (err) {
      setErrorOrder(err.message);
    } finally {
      setLoadingOrder(false);
    }
  };

  const reportOrder = async (filter) => {
    try {
      setLoadingReport(true);

      const res = await fetchService({
        method: 'GET',
        endpoint: endpoint + '/export',
        jwt,
        filter,
      });

      return `https://api.fiorettohelados.com${res.body}`;
    } catch (err) {
      throw setErrorOrder(err.message);
    } finally {
      setLoadingReport(false);
    }
  };

  const deleteOrder = async (data) => {
    try {
      setLoadingOrder(true);

      const res = await fetchService({
        method: 'DELETE',
        endpoint,
        jwt,
        data,
      });

      if (res.error) {
        throw new Error();
      }
      return true;
    } catch (err) {
      return false;
    } finally {
      setLoadingOrder(false);
    }
  };

  return {
    listOrder,
    loadingOrder,
    errorOrder,
    loadingReport,
    getOrder,
    addOrder,
    reportOrder,
    setListOrder,
    deleteOrder,
  };
};

export default useOrder;
