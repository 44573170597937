import React from 'react';
import { ModalMirror } from './index';

import { CustomButton } from '../Button';

import './modalConfirm.scss';

const ModalConfirm = ({ message, onClose, onCofirm }) => {
  return (
    <ModalMirror>
      <div className='modalConfirm'>
        <h3>{message}</h3>

        <div className='modalConfirm_btns'>
          <CustomButton color='primary' handleClick={onCofirm}>
            Aceptar
          </CustomButton>
          <CustomButton color='secondary' handleClick={onClose}>
            Cancelar
          </CustomButton>
        </div>
      </div>
    </ModalMirror>
  );
};

export default ModalConfirm;
