import React from 'react';

export default function Title({ children, size, color = 'ocean' }) {
  const sizeElements = {
    xl: 'h1',
    lg: 'h2',
    md: 'h3',
    sm: 'h4',
    xs: 'h5',
  };

  return React.createElement(
    sizeElements[size] || 'h1',
    {
      className: `color-${color}`,
    },
    children,
  );
}
