import React, { useState } from 'react';

const Context = React.createContext({});

export function UserContextProvider({ children }) {
  const [jwt, setJWT] = useState(() => window.localStorage.getItem('jwt'));
  const [user, setUser] = useState(
    () => JSON.parse(window.localStorage.getItem('user')) || '',
  );
  const [product, setProduct] = useState([]);
  return (
    <Context.Provider
      value={{
        jwt,
        setJWT,
        user,
        setUser,
        product,
        setProduct,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default Context;
