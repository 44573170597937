const URL = 'https://api.fiorettohelados.com/api/';

const fetchService = async ({
  endpoint,
  method,
  filter = '',
  jwt = '',
  data = false,
  formData = false,
}) => {
  const config = {
    method,
    headers: {},
  };

  // validamos si viene el token para agregarlo al encabezado
  if (jwt) {
    Object.assign(config.headers, { 'Authorization': `Bearer ${jwt}` });
  }

  // validamos si no viene formData agregamos encabezado
  if (formData === false) {
    Object.assign(config.headers, { 'Content-Type': 'application/json' });
  }

  // validamos si viene data para agregarlo al fetch
  if (data) {
    const body = { body: JSON.stringify(data) };
    Object.assign(config, body);
  }

  // validamos si viene formData agregamos el body sin JSON.stringify
  if (formData) {
    Object.assign(config, {
      body: formData,
    });
  }

  // enviamos el fetch
  const send = await fetch(`${URL}${endpoint}${filter}`, config);

  // retornamos la respuesta en promesa
  return send.json();
};

export default fetchService;
