import { useState, useContext } from 'react';

// Context
import Context from '../context/UserContext';

// Services
import fetchService from '../services/fetch';

const endpoint = 'user';

const useOrder = () => {
  const { jwt } = useContext(Context);
  const [listUser, setListUser] = useState([]);
  const [loadingUser, setLoadingUser] = useState(false);
  const [errorUser, setErrorUser] = useState(false);

  const getUser = async () => {
    try {
      setLoadingUser(true);
      const res = await fetchService({
        method: 'GET',
        jwt,
        endpoint,
      });

      if (res.error) {
        throw new Error(res.body);
      }

      setListUser(res.body);
    } catch (err) {
      setErrorUser(err.message);
    } finally {
      setLoadingUser(false);
    }
  };

  const deleteUser = async (id) => {
    try {
      setLoadingUser(true);
      const res = await fetchService({
        method: 'DELETE',
        endpoint,
        jwt,
        data: { id },
      });

      if (res.error) {
        throw new Error(res.body);
      }

      return true;
    } catch (err) {
      setErrorUser(err.message);
    } finally {
      setLoadingUser(false);
    }
  };

  const addUser = async (data) => {
    try {
      setLoadingUser(true);
      const res = await fetchService({
        method: 'POST',
        endpoint,
        jwt,
        formData: data,
      });

      if (res.error) {
        throw new Error(res.error);
      }

      return true;
    } catch (err) {
      setErrorUser(err.message);
    } finally {
      setLoadingUser(false);
    }
  };

  const registerPassword = async (data) => {
    try {
      setLoadingUser(true);
      const res = await fetchService({
        method: 'POST',
        endpoint: endpoint + '/register',
        data,
      });

      if (res.error) {
        throw new Error(res.body);
      }

      return true;
    } catch (err) {
      setErrorUser(err.message);
    } finally {
      setLoadingUser(false);
    }
  };

  const recoverPassword = async (data) => {
    try {
      setLoadingUser(true);
      const res = await fetchService({
        method: 'POST',
        endpoint: endpoint + '/recover',
        data,
      });

      if (res.error) {
        throw new Error(res.body);
      }

      return true;
    } catch (err) {
      setErrorUser(err.message);
    } finally {
      setLoadingUser(false);
    }
  };

  const changePassword = async (data) => {
    try {
      setLoadingUser(true);
      const res = await fetchService({
        method: 'POST',
        endpoint: endpoint + '/reset',
        data: { password: data.password },
        jwt: data.token,
      });

      if (res.error) {
        throw new Error(res.body);
      }

      return true;
    } catch (err) {
      setErrorUser('Esta url ya no esta disponible');
    } finally {
      setLoadingUser(false);
    }
  };

  return {
    listUser,
    loadingUser,
    errorUser,
    getUser,
    addUser,
    deleteUser,
    registerPassword,
    setListUser,
    recoverPassword,
    changePassword,
  };
};

export default useOrder;
