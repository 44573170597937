import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';

const CustomTableList = ({ body, titles }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label='simple table'>
        <TableBody>
          {titles.map((title) => (
            <TableRow key={title.id}>
              <TableCell>{title.label}</TableCell>
              <TableCell align={title.align}>{body[title.id]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTableList;
