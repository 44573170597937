import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

// Images
import ClientImage from '../../assets/image/clients.svg';
import OrdersImage from '../../assets/image/orders.svg';

// styles
import './dashboardAdmin.scss';

const DashboardAdmin = () => {
  const match = useRouteMatch();

  return (
    <div className='dashboardAdmin'>
      <Link to={`${match.url}/order`} className='dashboardAdmin_item'>
        <img src={OrdersImage} alt='lista de pedidos' />
        <h2>Lista de Pedidos</h2>
      </Link>
      <Link to={`${match.url}/client`} className='dashboardAdmin_item'>
        <img src={ClientImage} alt='lista de clientes' />
        <h2>Lista de Clientes</h2>
      </Link>
    </div>
  );
};

export default DashboardAdmin;
