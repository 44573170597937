import React, { useContext } from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';

// Context
import Context from './context/UserContext';

// Hooks
import useLogin from './hooks/useLogin';

// Components
import { Layout } from './components/Layout';

// Router
import ProductRouter from './pages/Product/ProductRouter';
import DashboardRouter from './pages/Dashboard/DashboardRouter';

export default function AppRouter() {
  const { isLogged } = useLogin();
  const match = useRouteMatch();
  const { user } = useContext(Context);
  const isAdmin = user.id_rol === 1;

  if (isLogged) {
    return (
      <Layout>
        <Switch>
          {isAdmin && (
            <Route
              path={`${match.url}/dashboard`}
              component={DashboardRouter}
            />
          )}

          <Route
            exact
            path={`${match.url}/productos`}
            component={ProductRouter}
          />

          {/* Si eres admin te mandamos al dashboard y si eres user a la lista de productos */}
          {isAdmin ? (
            <Redirect to={`${match.url}/dashboard`} />
          ) : (
            <Redirect to={`${match.url}/productos`} />
          )}
        </Switch>
      </Layout>
    );
  }

  return (
    <Switch>
      <Redirect to='/session' />
    </Switch>
  );
}
