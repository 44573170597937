import React from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';

// Hooks
import useLogin from '../../hooks/useLogin';

// Pages
import SignInPage from './SignInPage';
import RegisterPasswordPage from './RegisterPasswordPage';
import RecoverPasswordPage from './RecoverPasswordPage';
import ResetPasswordPage from './ResetPasswordPage';

const SessionRouter = () => {
  const { isLogged } = useLogin();
  const match = useRouteMatch();

  if (!isLogged) {
    return (
      <Switch>
        <Route exact path={`${match.url}/signin`} component={SignInPage} />
        <Route
          excat
          path={`${match.url}/register`}
          component={RegisterPasswordPage}
        />
        <Route
          excat
          path={`${match.url}/recover`}
          component={RecoverPasswordPage}
        />
        <Route
          excat
          path={`${match.url}/reset/:token`}
          component={ResetPasswordPage}
        />
        <Redirect to={`${match.url}/signin`} />
      </Switch>
    );
  }

  return (
    <Switch>
      <Redirect to='/app' />
    </Switch>
  );
};

export default SessionRouter;
