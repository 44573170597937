import React from 'react';
import { Link } from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import useLogin from '../../hooks/useLogin';

import { ModalMirror } from '../Modal';
import { Loader } from '../Loader';

import LogoImage from '../../assets/image/logo.png';

import './layout.scss';

const Layout = ({ children }) => {
  const { loadingLogin, logout } = useLogin();

  return (
    <div className='layout'>
      <div className='layout_nav container'>
        <div className='layout_nav_content content'>
          <Link to='/app' className='layout_logo'>
            <img src={LogoImage} alt='Logo' />
          </Link>

          <div className='layout_logout' onClick={logout}>
            <ExitToAppIcon />
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='content'>
          <div className='layout_content'>{children}</div>
        </div>
      </div>

      {loadingLogin && (
        <ModalMirror customClass='modal_transparent'>
          <Loader />
        </ModalMirror>
      )}
    </div>
  );
};

export default Layout;
