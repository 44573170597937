import { useState, useContext } from 'react';

// Context
import Context from '../context/UserContext';

// Services
import fetchService from '../services/fetch';

const endpoint = 'product';

const useProduct = () => {
  const { jwt, product, setProduct } = useContext(Context);
  const [listProduct, setListProduct] = useState([]);
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [errorProduct, setErrorProduct] = useState(false);

  const getProduct = async (filter = '') => {
    try {
      setLoadingProduct(true);
      if (product.length === 0) {
        const res = await fetchService({
          method: 'GET',
          jwt,
          endpoint,
          filter,
        });

        if (res.error) {
          throw new Error(res.body);
        }

        setListProduct(res.body);
        setProduct(res.body);
      } else {
        setListProduct(product);
      }
    } catch (err) {
      setErrorProduct(err.message);
    } finally {
      setLoadingProduct(false);
    }
  };

  const addAndEditProduct = async (data) => {
    try {
      setLoadingProduct(true);
      const res = await fetchService({
        method: 'POST',
        endpoint,
        jwt,
        formData: data,
      });

      if (res.error) {
        throw new Error(res.body);
      }

      return true;
    } catch (err) {
      setErrorProduct(err.message);
    } finally {
      setLoadingProduct(false);
    }
  };

  const deleteProduct = async (id) => {
    try {
      setLoadingProduct(true);
      const res = await fetchService({
        method: 'DELETE',
        jwt,
        endpoint,
        data: { id },
      });

      if (res.error) {
        throw new Error(res.body);
      }

      return true;
    } catch (err) {
      setErrorProduct(err.message);
    } finally {
      setLoadingProduct(false);
    }
  };

  return {
    listProduct,
    loadingProduct,
    errorProduct,
    getProduct,
    addAndEditProduct,
    deleteProduct,
    setListProduct,
  };
};

export default useProduct;
