import React, { useState, useEffect } from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';

// Hooks
import useUser from '../../hooks/useUser';
import useProduct from '../../hooks/useProduct';
import useProductAdmin from '../../hooks/useProductAdmin';

// Components
import { CustomTablePagination } from '../../components/Table';
import { CustomButton } from '../../components/Button';
import DetailsUser from './DetailsUser';
import ListProduct from './ListProduct';
import { ModalMirror, ModalConfirm } from '../../components/Modal';
import { Loader } from '../../components/Loader';

// style
import './listClientPage.scss';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: '25ch',
  },
}));

const ListClientPage = () => {
  const {
    listUser,
    errorUser,
    loadingUser,
    getUser,
    addUser,
    deleteUser,
    setListUser,
  } = useUser();
  const { loadingProduct, addAndEditProduct, deleteProduct } = useProduct();
  const {
    listProductAdmin,
    loadingProductAdmin,
    getProductAdmin,
    setListProductAdmin,
  } = useProductAdmin();
  const [loadingPage, setLoadingPage] = useState(true);
  const [detailsUser, setDetailsUser] = useState([]);
  const [modalAddEditProduct, setModalAddEditProduct] = useState(false);
  const [modalAddUser, setModalAddUser] = useState(false);
  const [formAdd, setFormAdd] = useState({
    email: '',
  });
  const [modalConfirm, setModalConfirm] = useState({
    isOpen: false,
    id: 0,
  });

  const classForm = useStyles();

  useEffect(async () => {
    await getUser();
    setLoadingPage(false);
  }, []);

  // evento para obtener el detalle del usuario y sus productos
  const handleSearchDetailsUser = async (user) => {
    // buscamos el detalle del usuario
    if (listProductAdmin.length > 0) {
      setListProductAdmin([]);
    }
    setDetailsUser(listUser.filter((item) => item.id === user.id));
  };

  // evento para obtener los productos del cliente
  const handleSerachProduct = async () => {
    if (listProductAdmin.length > 0) {
      setListProductAdmin([]);
    }
    await getProductAdmin(`?id_user=${detailsUser[0].id}`);
  };

  // evento para eliminar un producto del cliente
  const handleDeleteProduct = async () => {
    // validamos que el id no sea 0
    if (modalConfirm.id === 0) return;
    const validate = await deleteProduct(modalConfirm.id);

    if (validate === true) {
      setListProductAdmin(
        listProductAdmin.filter((item) => item.id !== modalConfirm.id),
      );
    }

    setModalConfirm({
      isOpen: false,
      id: 0,
    });
  };

  // evento para abrir el modal para confirmar eliminar
  const openModalConfirm = (id = 0) => {
    setModalConfirm({
      isOpen: true,
      id,
    });
  };

  // Evento para eliminar el user
  const handleDeleteUser = async () => {
    const validate = await deleteUser(detailsUser[0].id);

    if (validate === true) {
      setListUser(listUser.filter((item) => item.id !== detailsUser[0].id));
      setDetailsUser('');
      setListProductAdmin([]);
    }

    setModalConfirm({
      isOpen: false,
      id: 0,
    });
  };

  // evento para editar o crear los productos
  const handleModalAddEditProduct = () => {
    setModalAddEditProduct(!modalAddEditProduct);
  };

  // evento para enviar el formulario
  const handleSubmit = async (e) => {
    e.preventDefault();

    let data = new FormData(e.target);
    data.append('id_user', detailsUser[0].id);

    const validate = await addAndEditProduct(data);

    if (validate === true) {
      handleSerachProduct();
      handleModalAddEditProduct();
    }
  };

  // evento para enviar el formulario de crear user
  const handleSubmitUser = async (e) => {
    e.preventDefault();

    let data = new FormData(e.target);

    const validate = await addUser(data);

    if (validate === true) {
      await getUser();
      handleModalNewUser();
    }
  };

  // evento para agregar un nuevo usuario
  const handleModalNewUser = () => {
    setModalAddUser(!modalAddUser);
  };

  // evento que guarda el correo cuando se agrega un nuevo usuario
  const handleChangeAdd = (e) => {
    setFormAdd({
      ...formAdd,
      [e.target.name]: e.target.valule,
    });
  };

  if (loadingPage)
    return (
      <ModalMirror customClass='modal_transparent'>
        <Loader />
      </ModalMirror>
    );

  // definimos el header de la tabla
  const columns = [
    { id: 'name', label: 'Nombre', minWidth: 60 },
    { id: 'last_name', label: 'Apellido', minWidth: 90, align: 'center' },
  ];

  // definimos el cuerpo de la tabla
  const rows = [];

  listUser.forEach((user) => {
    if (user.id_rol !== 1) {
      rows.push({
        name: user.name,
        last_name: user.last_name,
        id: user.id,
      });
    }
  });

  return (
    <div className='listClientPage'>
      <div className='listClientPage_client listClientPage_item'>
        <div className='listClientPage_top'>
          <h2>Lista de clientes</h2>
          <CustomButton
            handleClick={handleModalNewUser}
            disabled={false}
            size='sm'
            transparent
          >
            <AddCircleIcon />
          </CustomButton>
        </div>
        <CustomTablePagination
          header={columns}
          body={rows}
          handleClick={handleSearchDetailsUser}
        />
      </div>
      {detailsUser.length > 0 && (
        <div className='listClientPage_details listClientPage_item'>
          <h2>Detalles del cliente</h2>

          <DetailsUser user={detailsUser[0]} />

          <div className='listClientPage_details_btns'>
            <CustomButton
              color='primary'
              handleClick={handleSerachProduct}
              disabled={false}
              text='Ver Productos'
            />
            <CustomButton
              color='primary'
              handleClick={handleModalAddEditProduct}
              disabled={false}
              text='Agregar y Editar Productos'
            />
            <CustomButton
              color='secondary'
              handleClick={openModalConfirm}
              disabled={false}
              text='Eliminar Cliente'
            />
          </div>
        </div>
      )}
      {listProductAdmin.length > 0 && (
        <div className='listClientPage_products listClientPage_item'>
          <h2>Productos del cliente</h2>
          <ListProduct
            products={listProductAdmin}
            responseDeleteProduct={openModalConfirm}
            loading={loadingProductAdmin}
          />
        </div>
      )}
      {modalAddEditProduct && (
        <ModalMirror onClose={handleModalAddEditProduct}>
          <form
            onSubmit={handleSubmit}
            className='listClientPage_form'
            encType='multipart/form-data'
          >
            <input
              type='file'
              name='excel'
              id='excel'
              className='listClientPage_none'
            />
            <label htmlFor='excel' className='listClientPage_label'>
              Cargar Archivo de Excel
            </label>
            <CustomButton
              color='primary'
              type='submit'
              disabled={false}
              text='Enviar'
            />
          </form>
        </ModalMirror>
      )}
      {modalAddUser && (
        <ModalMirror onClose={handleModalNewUser}>
          {loadingUser ? (
            <ModalMirror customClass='modal_transparent'>
              <Loader />
            </ModalMirror>
          ) : (
            <form
              onSubmit={handleSubmitUser}
              className='listClientPage_form'
              encType='multipart/form-data'
            >
              {errorUser && <p>{errorUser}</p>}
              <FormControl
                className={clsx(classForm.margin, classForm.textField)}
                variant='outlined'
              >
                <InputLabel htmlFor='email'>Correo</InputLabel>
                <OutlinedInput
                  id='email'
                  type='email'
                  value={formAdd.email}
                  name='email'
                  required
                  onChange={handleChangeAdd}
                  labelWidth={50}
                />
              </FormControl>

              <input
                type='file'
                name='excel'
                id='excel'
                className='listClientPage_none'
              />
              <label htmlFor='excel' className='listClientPage_label'>
                Cargar Archivo de Excel
              </label>
              <CustomButton
                color='primary'
                type='submit'
                disabled={false}
                text='Enviar'
              />
            </form>
          )}
        </ModalMirror>
      )}
      {modalConfirm.isOpen && (
        <ModalConfirm
          message='¿ Desea eliminar al cliente ?'
          onCofirm={handleDeleteUser}
          onClose={() => setModalConfirm({ isOpen: false, id: 0 })}
        />
      )}
      {modalConfirm.isOpen && modalConfirm.id > 0 && (
        <ModalConfirm
          message='¿ Desea eliminar el producto del cliente ?'
          onCofirm={handleDeleteProduct}
          onClose={() => setModalConfirm({ isOpen: false, id: 0 })}
        />
      )}

      {(loadingProductAdmin || loadingProduct || loadingUser) && (
        <ModalMirror customClass='modal_transparent'>
          <Loader />
        </ModalMirror>
      )}
    </div>
  );
};

export default ListClientPage;
