import { useState, useContext } from 'react';

// Context
import Context from '../context/UserContext';

// Services
import fetchService from '../services/fetch';

const endpoint = 'order_product';

const useOrder = () => {
  const { jwt } = useContext(Context);
  const [listOrderProduct, setListOrderProduct] = useState([]);
  const [loadingOrderProduct, setLoadingOrderProduct] = useState(false);
  const [errorOrderProduct, setErrorOrderProduct] = useState(false);

  // funcion para obtener el listado detallado del pedido
  const getOrderProduct = async (filter) => {
    try {
      setLoadingOrderProduct(true);

      const res = await fetchService({
        method: 'GET',
        endpoint,
        filter,
        jwt,
      });

      if (res.error) {
        throw new Error(res.body);
      }

      setListOrderProduct(res.body);
    } catch (err) {
      setErrorOrderProduct(err.message);
    } finally {
      setLoadingOrderProduct(false);
    }
  };

  return {
    listOrderProduct,
    loadingOrderProduct,
    errorOrderProduct,
    setListOrderProduct,
    getOrderProduct,
  };
};

export default useOrder;
