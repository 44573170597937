import * as React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { UserContextProvider } from './context/UserContext';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';

import SessionRouter from './pages/Session/SessionRouter';
import AppRouter from './AppRouter';

import './assets/styles/main.scss';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#F69255',
    },
    secondary: {
      main: '#f48fb1',
    },
  },
});

const App = () => (
  <ThemeProvider theme={theme}>
    <UserContextProvider>
      <BrowserRouter>
        <Switch>
          <Route path='/session' component={SessionRouter} />
          <Route path='/app' component={AppRouter} />
          <Redirect to='/session' />
        </Switch>
      </BrowserRouter>
    </UserContextProvider>
  </ThemeProvider>
);

export default App;
