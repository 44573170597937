import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  makeStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

// Components
import { Title } from '../../components/Title';
import { Form } from '../../components/Form';
import { Footer } from '../../components/Footer';

// Hooks
import useUser from '../../hooks/useUser';

// Img
import StoreImage from '../../assets/image/icono.png';
import AvatarMan from '../../assets/image/avatar-man.svg';
import BgLogin from '../../assets/image/bg-login.svg';

// Css
import './login.scss';
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: '100%',
  },
}));

const RecoverPasswordPage = () => {
  const [dataForm, setDataForm] = useState({
    email: '',
  });
  const { loadingUser, recoverPassword } = useUser();
  const [message, setMessage] = useState('');

  const classForm = useStyles();

  const handleChange = (e) => {
    setDataForm({
      ...dataForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (value) => {
    const notification = await recoverPassword(value);

    if (notification === true) {
      setMessage('Se ha enviado un correo');
    }
  };

  return (
    <div className='login'>
      <div className='login_svg'>
        <img className='login_svg_bg' src={BgLogin} alt='Fondo Login' />
        <img className='login_svg_truck' src={StoreImage} alt='Tienda' />
      </div>
      <div className='login_content'>
        <img className='login_icon' src={AvatarMan} alt='Perfil' />
        <div className='login_title'>
          <Title size='xl'>Recuperar contraseña</Title>
        </div>
        <Form onSubmit={handleSubmit}>
          <div className='login_form'>
            {message && <Alert severity='success'>{message}</Alert>}
            <FormControl
              className={clsx(classForm.margin, classForm.textField)}
              variant='outlined'
            >
              <InputLabel htmlFor='email'>Correo</InputLabel>
              <OutlinedInput
                id='email'
                type='email'
                value={dataForm.email}
                name='email'
                required
                onChange={handleChange}
                labelWidth={50}
              />
            </FormControl>

            <Button
              type='submit'
              variant='contained'
              color='primary'
              disabled={loadingUser}
            >
              Recuperar
            </Button>
          </div>
        </Form>
      </div>

      <Footer />
    </div>
  );
};

export default RecoverPasswordPage;
