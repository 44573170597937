import React, { useState, useEffect, useContext } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import Context from '../../context/UserContext';

// Components
import { ModalMirror, ModalChangeQuantity, ModalConfirm } from './index';
import { CustomButton } from '../Button';
import { Loader } from '../Loader';

// Hooks
import useShopping from '../../hooks/useShopping';
import useOrder from '../../hooks/useOrder';

// Style
import './modalShopping.scss';

const useStyles = makeStyles({
  table: {
    maxHeight: 330,
  },
});

const ModalShopping = ({ onClose, countShopping }) => {
  const { user } = useContext(Context);
  const {
    listShopping,
    loadingShopping,
    getShopping,
    addShopping,
    removeShopping,
    setListShopping,
  } = useShopping();
  const [loadingModal, setLoadingModal] = useState(true);
  const [modalChangeQuantity, setModalChangeQuantity] = useState(false);
  const [modalConfirm, setModalConfirm] = useState({
    isOpen: false,
    product: 0,
  });
  const classes = useStyles();
  const [dataChange, setDataChange] = useState({
    quantity: 0,
    oldQuantity: 0,
    id_product: null,
  });
  const { loadingOrder, addOrder } = useOrder();
  const [totalShopping, setTotalShopping] = useState(0);
  const [downLoadPdf, setDownLoadPdf] = useState('');

  useEffect(async () => {
    await getShopping();
    setLoadingModal(false);
  }, []);

  useEffect(() => {
    let subTotal = 0;
    let totalFreight = 0;

    listShopping.forEach((product) => {
      subTotal = subTotal + product.price * product.quantity;
    });

    const realFreight = (user.freight / subTotal) * 100;
    const maxFreight = 3.5;

    if (realFreight >= maxFreight) {
      totalFreight = ((realFreight - maxFreight) * subTotal) / 100;
    }

    setTotalShopping({
      subTotal,
      totalFreight,
    });
  }, [listShopping]);

  // evento para cambiar la cantidad del producto en el carrito
  const handleChangeQuantity = (e) => {
    setDataChange({
      ...dataChange,
      quantity: parseInt(e.target.value, 10),
    });
  };

  // evento para abrir el modal para cambiar la cantidad del producto
  const handleModalChangeQuantity = (quantity, { id_product, price }) => {
    setDataChange({
      quantity: quantity,
      id_product,
      oldQuantity: parseInt(quantity, 10),
      price,
    });
    setModalChangeQuantity(!modalChangeQuantity);
  };

  // evento que cierra el modal para cambiar la cantidad del producto
  const handleCloseModalChangeQuantity = () => {
    setModalChangeQuantity(false);
    setDataChange(false);
  };

  // evento para enviar la nueva cantidad del producto a la base de datos
  const handleCheckChangeQuantity = async () => {
    await addShopping(dataChange);
    setListShopping(
      listShopping.map((product) => {
        if (product.id_product === dataChange.id_product) {
          return {
            ...product,
            quantity: parseInt(dataChange.quantity, 10),
            subTotal: parseFloat(
              dataChange.quantity * dataChange.price,
            ).toFixed(2),
          };
        }

        return product;
      }),
    );

    handleCloseModalChangeQuantity();
  };

  // handle que abre un modal para confirmar
  const openModalConfirm = (idProduct) => {
    setModalConfirm({
      isOpen: true,
      product: idProduct,
    });
  };

  // evento para sacar del carrito un producto
  const handleOutProduct = async () => {
    // validamos que el id del producto no sea 0
    if (modalConfirm.product === 0) return;
    const remove = await removeShopping({ id_product: modalConfirm.product });

    if (remove) {
      setListShopping(
        listShopping.filter((item) => item.id_product !== modalConfirm.product),
      );
      countShopping();
    }

    setModalConfirm({
      isOpen: false,
      product: 0,
    });
  };

  // evento para enviar el pedido
  const handleSendOrder = async () => {
    const filePdf = await addOrder(listShopping);

    // si se hizo el registro limpiamos todo y cerramos modal
    if (filePdf) {
      setDownLoadPdf(filePdf);
      // onClose(true);
    }
  };

  return (
    <ModalMirror onClose={onClose} size='lg'>
      {loadingModal ? (
        <Loader />
      ) : (
        <>
          <TableContainer component={Paper} className={classes.table}>
            <Table aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>Producto</TableCell>
                  <TableCell align='center'>Precio</TableCell>
                  <TableCell align='center'>Cantidad</TableCell>
                  <TableCell align='right'>Total</TableCell>
                  <TableCell align='right' />
                </TableRow>
              </TableHead>
              <TableBody>
                {listShopping.map((product) => {
                  return (
                    <TableRow key={product.id}>
                      <TableCell>{product.name}</TableCell>
                      <TableCell align='right'>{`$ ${product.price}`}</TableCell>
                      <TableCell align='center'>
                        <CustomButton
                          color='primary'
                          handleClick={() =>
                            handleModalChangeQuantity(product.quantity, product)
                          }
                          disabled={loadingShopping || loadingOrder}
                          size='sm'
                          text={product.quantity}
                        />
                      </TableCell>
                      <TableCell align='right'>
                        {`$ ${parseFloat(
                          product.price * product.quantity,
                        ).toFixed(2)}`}
                      </TableCell>
                      <TableCell>
                        <CustomButton
                          color='secondary'
                          handleClick={() =>
                            openModalConfirm(product.id_product)
                          }
                          disabled={loadingShopping || loadingOrder}
                          size='sm'
                          text='X'
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <div className='modalShopping_final'>
            <CustomButton
              color='primary'
              handleClick={handleSendOrder}
              disabled={loadingShopping || loadingOrder}
              text='Hacer pedido'
            />
            <div className='modalShopping_final_total'>
              <p>
                subTotal :{' '}
                <strong>
                  $ {parseFloat(totalShopping.subTotal).toFixed(2)}
                </strong>
              </p>
              <p>
                Flete :{' '}
                <strong>
                  $ {parseFloat(totalShopping.totalFreight || 0).toFixed(2)}
                </strong>
              </p>
              <p>
                Total :{' '}
                <strong>
                  ${' '}
                  {parseFloat(
                    totalShopping.subTotal + totalShopping.totalFreight || 0,
                  ).toFixed(2)}
                </strong>
              </p>
            </div>
          </div>
        </>
      )}

      {modalChangeQuantity && (
        <ModalChangeQuantity
          onClose={handleCloseModalChangeQuantity}
          onChange={handleChangeQuantity}
          disabled={loadingShopping}
          onClickCheck={handleCheckChangeQuantity}
          quantity={dataChange.quantity}
        />
      )}
      {modalConfirm.isOpen && (
        <ModalConfirm
          message='¿ Desea eliminar el producto del carrito ?'
          onCofirm={handleOutProduct}
          onClose={() => setModalConfirm({ isOpen: false, product: 0 })}
        />
      )}
      {downLoadPdf && (
        <ModalMirror onClose={() => onClose(true)}>
          <Alert severity='success'>
            <a
              href={`https://api.fiorettohelados.com${downLoadPdf}`}
              download='nombre'
              className='listOrderProduct_download'
              target='_blank'
              rel='noreferrer'
            >
              Ver Factura
            </a>
          </Alert>
        </ModalMirror>
      )}
    </ModalMirror>
  );
};

export default ModalShopping;
